export const COLUMNS = [
  {
    title: 'Имя топика',
    dataIndex: 'topic',
    key: 'topic',
    editable: true,
  },
  {
    title: 'Канал',
    dataIndex: 'result_channel',
    key: 'result_channel',
    isChannel: true,
    render: (text: string | undefined) => text ?? 'Канал не найден',
  },
].map(col => (
  !col.editable
    ? {
      ...col,
      onCell: (record: any) => ({
        record,
        dataIndex: col.dataIndex,
        isChannel: col.isChannel,
      }),
    }
    : {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    }
))
