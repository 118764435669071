import type { ChannelsResponse } from 'entities/channel'
import type { DirTreeData } from 'entities/directory'
import type { ModuleSettings } from 'entities/module'
import keyToId from 'shared/lib/key-to-id'
import createObjectsArray from 'shared/lib/objects/create-objects-array'
import type { ObjectsTableData, ObjectType } from 'shared/ui/module-objects'

const createPosTableData = (settings: ModuleSettings<ObjectType>, channelsData: ChannelsResponse | undefined, selectedFolder: DirTreeData): ObjectsTableData[] => {
  const objects = createObjectsArray(settings, +keyToId(selectedFolder.key))

  return settings.config.objects_list.reduce<ObjectsTableData[]>((acc, curr) => {
    if (objects.includes(curr.ind)) {
      if (channelsData) {
        const channelsObj = {
          id: { name: '', id: '' },
          name: { name: '', id: '' },
          lat: { name: '', id: '' },
          lon: { name: '', id: '' },
          alt: { name: '', id: '' },
          azm: { name: '', id: '' },
          spd: { name: '', id: '' },
        }

        channelsData.channels.forEach(ch => {
          switch (true) {
            case ch.uid === curr.object.id_channel:
              channelsObj.id = { name: ch.name, id: ch.uid }
              break
            case ch.uid === curr.object.name_channel:
              channelsObj.name = { name: ch.name, id: ch.uid }
              break
            case ch.uid === curr.object.lat_channel:
              channelsObj.lat = { name: ch.name, id: ch.uid }
              break
            case ch.uid === curr.object.lon_channel:
              channelsObj.lon = { name: ch.name, id: ch.uid }
              break
            case ch.uid === curr.object.alt_channel:
              channelsObj.alt = { name: ch.name, id: ch.uid }
              break
            case ch.uid === curr.object.azm_channel:
              channelsObj.azm = { name: ch.name, id: ch.uid }
              break
            case ch.uid === curr.object.spd_channel:
              channelsObj.spd = { name: ch.name, id: ch.uid }
              break
          }
        })

        acc.push({
          key: 'object-' + curr.ind,
          label: curr.object.label,
          id: channelsObj.id,
          name: channelsObj.name,
          lat: channelsObj.lat,
          lon: channelsObj.lon,
          alt: channelsObj.alt,
          azm: channelsObj.azm,
          spd: channelsObj.spd,
        })
      } else {
        acc.push({
          key: 'object-' + curr.ind,
          label: curr.object.label,
          id: { name: '', id: '' },
          name: { name: '', id: '' },
          lat: { name: '', id: '' },
          lon: { name: '', id: '' },
          alt: { name: '', id: '' },
          azm: { name: '', id: '' },
          spd: { name: '', id: '' },
        })
      }
    }

    return acc
  }, [])
}

export default createPosTableData
